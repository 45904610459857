@import "antd/dist/reset.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.chart-tooltip-value {
  padding-left: 12px;
  font-weight: 600;
  text-align: right;
}

.watermark {
  -webkit-transform: rotate(331deg);
  -moz-transform: rotate(331deg);
  -o-transform: rotate(331deg);
  transform: rotate(331deg);
  font-size: 12em;
  color: rgba(255, 5, 5, 0.17);
  position: absolute;
  text-transform: uppercase;
  padding-left: 10%;
}

.accounts .ant-collapse-content-box {
  padding: 4px 6px !important;
}

.printable-table .ant-table-title {
  padding: 0 !important;
}

.ant-input-number-disabled,
.ant-picker-disabled {
  color: rgba(0, 0, 0, 0.75) !important;
  background-color: #f9fafb !important;
}

.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.75) !important;
}

/* .ant-input-number-handler-wrap {
  display: none !important;
} */
